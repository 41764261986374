<template>
  <div>
    <!-- <div @click="$router.go(-1)"
      style="display: flex;align-items: center;margin-left: 20px;font-size: 20px;cursor: pointer;margin-top: 20px;">
      <a-icon style="font-size: 30px;margin-right: 10px;" type="left-circle" />
      <span>返回</span>
    </div> -->
    <a-card class="card" title="基本信息" :bordered="false">
      <a-form-model>
        <a-row class="form-row">
          <a-col :lg="6" :md="12" :sm="24">
            <a-form-model-item  label="客户名称">
              <a-input v-model="details.customerName" disabled />
            </a-form-model-item>
          </a-col>
          <a-col :xl="{ span: 7, offset: 2 }" :lg="{ span: 8 }" :md="{ span: 12 }" :sm="24">
            <a-form-model-item ref="sellerId" prop="sellerId"  label="卖方主体">
              <a-input v-model="details.sellerName" disabled />
            </a-form-model-item>
          </a-col>
          <a-col :xl="{ span: 7, offset: 2 }" :lg="{ span: 8 }" :md="{ span: 12 }" :sm="24">
            <a-form-model-item ref="invoiceAmount" prop="invoiceAmount" label="合计开票金额">
              <div style="position: relative;">
                <a-input  v-model="details.invoiceAmount" disabled  />
                <span style="position: absolute;right: 10px;top: 50%;transform: translateY(-50%);">元</span>
              </div>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row class="form-row">
          <a-col :lg="6" :md="12" :sm="24">
            <a-form-model-item label="M+" ref="businessExpenses" prop="businessExpenses">
              <div style="position: relative;">
                <a-input v-model="details.businessExpenses" disabled/>
                <span style="position: absolute;right: 10px;top: 50%;transform: translateY(-50%);">元</span>
              </div>
            </a-form-model-item>
          </a-col>
          <a-col :xl="{ span: 7, offset: 2 }" :lg="{ span: 8 }" :md="{ span: 12 }" :sm="24">  
            <a-form-model-item label="预期出库时间" >
              <a-date-picker style="width: 100%;" disabled :default-value="moment(formatDate(details.sellingTime), dateFormat)" :format="dateFormat" />
            </a-form-model-item>
          </a-col>
          <a-col :xl="{ span: 7, offset: 2 }" :lg="{ span: 8 }" :md="{ span: 12 }" :sm="24">  
            <a-form-model-item label="预计回款时间" >
              <a-date-picker style="width: 100%;" v-if="details.expectReturnTime" disabled :default-value="moment(formatDate(details.expectReturnTime), dateFormat)" :format="dateFormat" />
              <span v-else>-</span>
            </a-form-model-item>
          </a-col>
          <a-col :lg="6" :md="12" :sm="24">
            <a-form-model-item label="项目等级">
              <a-select style="width: 100%"
                  placeholder="请选择项目等级"
                  disabled
                  v-model="details.projectType"
                  >
                  <a-select-option :value="item.id" :label="item.name"
                      v-for=" (item, index) in [{ id: '1', name: '投标' }, { id: '2', name: '非投标' }]"
                      :key="index">
                      {{ item.name }}
                  </a-select-option>
                </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :xl="{ span: 7, offset: 2 }" :lg="{ span: 8 }" :md="{ span: 12 }" :sm="24">
          <a-form-model-item label="发票">
            <a-select disabled v-model="details.invoice" placeholder="请选择是否需要发票" option-label-prop="label">
              <a-select-option :value="1" label="开票">开票</a-select-option>
              <a-select-option :value="2" label="不开票">不开票</a-select-option>
              <a-select-option :value="3" label="未知">未知</a-select-option>
            </a-select>
        </a-form-model-item>
          </a-col>
          <a-col :xl="{ span: 7, offset: 2 }" :lg="8" :md="12" :sm="24">
            <a-form-model-item label="回款方式">
              <a-select style="width: 76%"
                  disabled
                  placeholder="请选择回款方式"
                  v-model="details.repayType"
                  >
                  <a-select-option :value="item.id" :label="item.name"
                      v-for=" (item, index) in [{ id: '1', name: '电汇' }, { id: '2', name: '支票' }, { id: '3', name: '现金' }, { id: '4', name: '其他' }]"
                      :key="index">
                      {{ item.name }}
                  </a-select-option>
                </a-select>
            </a-form-model-item>
          </a-col>
          </a-row>
          <a-row>
            <a-col :lg="6" :md="12" :sm="24">
            <a-form-model-item label="回款客户名称" required>
              <a-input-group compact>
                <a-select :default-value="0" v-model="returnCustomerType" style="width: 100px"  disabled>
                  <a-select-option :value="0">
                    搜索公司
                  </a-select-option>
                  <a-select-option :value="1">
                    手动输入
                  </a-select-option>
                </a-select>

                <a-select disabled style="width: 65%" show-search v-if="returnCustomerType == 0" :value="searchReturnName"
                  placeholder="请输入客户名称" :default-active-first-option="false" :show-arrow="false" :filter-option="false"
                  :not-found-content="null" @search="fetchUserReturn" @change="handleSearchReturnChange">
                  <a-select-option v-for="d in customerReturnData" :key="d.name" :label="d.name" :value="d.name">
                    {{ d.name }}
                  </a-select-option>
                </a-select>
                <a-input disabled style="width: 65%" v-else placeholder="请输入客户名称" v-model="details.returnCustomerName" />
              </a-input-group>
              <div class="lists" v-for="(item,index) in cusomerData" :key="index" >
                  {{item}}
              </div>
            </a-form-model-item>
          </a-col>
          <a-col :xl="{ span: 7, offset: 2 }" :lg="{ span: 8 }" :md="{ span: 12 }" :sm="24" v-if="details.sfaSaleContract">
            <a-form-model-item label="是否需要签订合同" required >
              <a-radio-group v-model="details.sfaSaleContract.type" disabled>
                <a-radio :value="2"> 需要签订 </a-radio>
                <a-radio :value="1"> 无需签订 </a-radio>
              </a-radio-group>
              <div v-if="details.sfaSaleContract.type == 2" style="display: flex;align-items: center;">
                <div style="display:flex;">
                  <span>签订合同份数：</span>
                  <a-select disabled v-model="details.sfaSaleContract.quantity" style="width: 70px" placeholder="">
                    <a-select-option :value="item" v-for="item in 10" :key="item">
                      {{ item }}
                    </a-select-option>
                  </a-select>
                </div>
                <div style="display:flex;margin-left:5px;">
                  <span>需回合同份数：</span>
                  <a-select disabled v-model="details.sfaSaleContract.revertQuantity" style="width: 70px" placeholder="">
                    <a-select-option :value="item" v-for="item in 10" :key="item">
                      {{ item }}
                    </a-select-option>
                  </a-select>
                </div>
              </div>
              <div v-if="details.sfaSaleContract.type == 1" style="display: flex;align-items:center">
                <span style="width:50px;">备注：</span>
                <a-input placeholder="请输入" disabled v-model="details.sfaSaleContract.notes"></a-input>
              </div>
            </a-form-model-item>
          </a-col>
          <a-col :xl="{ span: 7, offset: 2 }" :lg="{ span: 8 }" :md="{ span: 12 }" :sm="24">
            <div class="price-box">
              <div style="border-right: 1px solid #EBEAED;">
                <p>合计实际收款</p>
                <span>{{ getAmount() }}元</span>
              </div>
              <div>
                <p>平均折扣:</p>
                <span v-if="details.averageDiscount">{{ details.averageDiscount }}</span> <span v-else>0</span> 折
              </div>
            </div>
          </a-col>
        </a-row>
      </a-form-model>
    </a-card>

    <card :detailsArr="details.sfaSaleDocketProductList" type="details" :isBackShow="false"></card>
    
  </div>
</template>
  
<script>
import moment from 'moment';
import { request } from '@/utils/request'
import card from './components/card.vue'
export default {
  name: 'addForm',
  components: { card },
  data() {
    return {
      details: null,
      cusomerData:[],//回款客户名称数组
      dateFormat:"YYYY-MM-DD",
      returnCustomerType:1
    }
  },
  mounted() {
    // 详情
    request(process.env.VUE_APP_API_BASE_URL + 'sfa/saledocket/'+this.$route.query.id, 'get').then(res => {
      this.details = res.data.data;
      this.details.projectType = this.details.projectType?String(this.details.projectType) : null
      this.cusomerData = this.details.returnCustomerName.split(',')
      this.details.repayType = this.details.repayType?String(this.details.repayType) : null
    })
  },
  methods: {
    moment,
    formatDate(timestamp) {
      const date = new Date(timestamp);
      return date.toISOString().split('T')[0];
    },
    getAmount(){ 
      // businessExpenses业务支出     invoiceAmount 开票金额
      this.details.amount = this.details.invoiceAmount - this.details.businessExpenses
      return this.details.invoiceAmount - this.details.businessExpenses
    },
    back() {
      this.$router.replace('/sfa_workOrder/OutboundList')
    },

  }
}
</script>
  
<style lang="less" scoped>
.card {
  margin-bottom: 24px;
}

::v-deep .ant-card-body {
  padding: 24px !important;
}

.price-box {
  display: flex;
  border-radius: 6px;
  margin-top: 6px;
  background: rgba(85, 66, 246, 0.03);
}

.price-box div {
  padding: 10px 0;
  box-sizing: border-box;
  flex: 1;
  font-size: 16px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  color: #010101;
  text-align: center;
}

.price-box span {
  font-size: 18px;
  color: rgba(255, 0, 0, 1);
}


::v-deep .ant-input[disabled] {
  background: #fff;
  color: #333;
}
</style>
  